import { createRouter, createWebHistory } from 'vue-router';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { nextTick } from "vue";

const routes = [
  {
    path: "/",
    name: "sign-in",
    component: () => import("../views/SigninView.vue"),
    meta: {
      title: "Sign in",
    },
  },

  {
    path: "/sign-in",
    name: "sign-in2",
    component: () => import("../views/SigninView.vue"),
    meta: {
      title: "Sign in",
    },
  },

  {
    path: "/users",
    name: "users",
    component: () => import("../views/UsersView.vue"),
    meta: {
      
      title: "Users",
    },
  },

  {
    path: "/models",
    name: "models",
    component: () => import("../views/ModelsView.vue"),
    meta: {
      
      title: "Models",
    },
  },

  {
    path: "/payouts",
    name: "payouts",
    component: () => import("../views/PayoutView.vue"),
    meta: {
      
      title: "Payouts",
    },
  },

  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/UsersView.vue"),
    meta: {
      
      title: "Reports",
    },
  },

  {
    path: "/kyc",
    name: "kyc",
    component: () => import("../views/KycView.vue"),
    meta: {
      
      title: "Kyc",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    };
  },
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      //alert("You do not have access");
      next("/sign-in");
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title;
  });
});

export default router
